import Image from 'next/image';
import cx from 'classnames';

import { FragmentType, getFragmentData, graphql } from '@/lib/gql';
import { truncateValue } from '@/lib/truncate-value';

import { DiffItem } from './diff-item';
import styles from './exposures.module.scss';

export function Exposures({ data: dataParam }: { data: readonly FragmentType<typeof fragment>[] }) {
  const data = getFragmentData(fragment, dataParam);

  return data.map((it, index) => {
    const isErc20 = /erc20/i.test(it.asset.type);
    return (
      <div key={index} className={cx(styles.container, isErc20 && styles.erc20)}>
        <div className={styles.asset}>
          <div className={cx(styles.assetLogo, !it.asset.logoUrl && styles.empty)}>
            <Image src={it.asset.logoUrl ?? ''} alt="" fill objectFit={isErc20 ? 'contain' : 'cover'} />
          </div>

          {(!!it.asset.name || !!it.asset.address) && (
            <div>
              {!!it.asset.name && <p className={styles.assetName}>{it.asset.name}</p>}
              {!!it.asset.address && <p className={styles.assetAddress}>{truncateValue(it.asset.address)}</p>}
            </div>
          )}
        </div>

        {it.spenders.map((spender, index) => {
          return <DiffItem key={index} data={spender.exposure} currency={it.asset.symbol} layout="exposures" />;
        })}
      </div>
    );
  });
}

const fragment = graphql(`
  fragment OperationSimulation_Exposures on AddressAssetExposure {
    asset {
      type
      address
      logoUrl
      name
      symbol
    }
    spenders {
      __typename
      ... on Erc1155Exposure {
        address
        exposure {
          __typename
          tokenId
          value
          logoUrl
          summary
          usdPrice
        }
      }
      ... on Erc20Exposure {
        address
        approval
        expiration
        summary
        exposure {
          __typename
          summary
          rawValue
          usdPrice
          maybeValue: value
        }
      }
      ... on Erc721Exposure {
        address
        isApprovedForAll
        summary
        exposure {
          __typename
          tokenId
          logoUrl
          summary
          usdPrice
        }
      }
    }
  }
`);
