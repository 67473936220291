'use client';
import React from 'react';
import { Toaster, toast } from 'react-hot-toast';

import { Toast, ToastProps } from '@/lib/io-kit/Toast';

export function ToastRoot() {
  return (
    <Toaster
      toastOptions={{
        style: {
          background: 'transparent',
          border: 'none',
          padding: '0',
          margin: '0',
          boxShadow: 'none',
        },
      }}
    />
  );
}

export function showDismissableAlertToast(
  title: string,
  message: React.ReactNode,
  variant: ToastProps['variant'],
  width?: '24rem' | '32rem',
) {
  toast((t) => <Toast title={title} content={message} variant={variant} onDismiss={() => toast.dismiss(t.id)} />, {
    position: 'bottom-right',
    duration: 8000,
    style: {
      minWidth: width || '24rem',
    },
  });
}
