'use client';
import { useTranslations } from 'next-intl';

import { CryptoAddressOrId } from '@/components/CryptoAddressOrId';
import { CryptoAmount } from '@/components/CryptoAmount';
import { GenericMessage } from '@/lib/web3-access';
import { useFormatter } from '@/lib/intl';
import { truncateValue } from '@/lib/truncate-value';
import { convertWeiToEther } from '@/lib/web3-utils';

import styles from './DetailsDataCard.module.scss';

export function DetailsDataCard({
  detailKey,
  value,
  message,
}: {
  detailKey: string;
  value: string;
  message: GenericMessage;
}) {
  const t = useTranslations('Components.Web3Modals.Components.RequestDataCard');
  const formatter = useFormatter();

  switch (detailKey) {
    case 'token':
    case 'spender': {
      return <CryptoAddressOrId value={value} showCopyIcon={true} />;
    }
    case 'from': {
      const fromInfo = message.message.find((msg) => msg.title === 'vaultName')?.description;
      const fromFormattedValue = fromInfo ? `${t('vault')} ${truncateValue(fromInfo)}` : '';
      return <AddressGroup address={value} additionalInfo={fromFormattedValue} />;
    }
    case 'to': {
      const toInfo = message.message.find((msg) => msg.title === 'dapp')?.description;
      return <AddressGroup address={value} additionalInfo={toInfo} />;
    }
    case 'amount':
    case 'value':
    case 'gas': {
      const convertedValue = convertWeiToEther(value);
      const formattedAmount = formatter.cryptoFull({ value: convertedValue });
      const currency = message.message.find((msg) => msg.title === 'currency')?.description;

      if (convertedValue.lt(1e-6) && convertedValue.gt(0)) return `<0.000001 ${currency}`;
      else if (convertedValue.gt(1e28)) return t('infinite');

      return <CryptoAmount amount={formattedAmount} className={styles.amount} currency={currency} />;
    }
    case 'createdAt':
    case 'sigDeadline':
    case 'expiration': {
      return <DateTimestamp value={value} />;
    }
    default: {
      return <>{value}</>;
    }
  }
}

function AddressGroup({ address, additionalInfo }: { address: string; additionalInfo?: string }) {
  return (
    <div className={styles.addressGroup}>
      {additionalInfo && <div className={styles.additionalInfo}>{additionalInfo}</div>}
      <CryptoAddressOrId value={address} showCopyIcon={true} />
    </div>
  );
}

function DateTimestamp({ value }: { value: string }) {
  const formatter = useFormatter();

  const date = new Date(Number(value) * 1000);
  const dateFormatted = formatter.date({ value: date });
  const timeFormatted = formatter.time({ value: date });
  return (
    <time dateTime={date.toISOString()} title={`${dateFormatted} ${timeFormatted}`} className={styles.textLight}>
      <div>
        {dateFormatted} - {timeFormatted}
      </div>
    </time>
  );
}
