import { useTranslations } from 'next-intl';

import { graphql } from '@/lib/gql';
import { IdentityStatus, StatusChangeResult } from '@/lib/gql/graphql';
import { LinkInternal } from '@/components/Link';
import { LinkTo } from '@/lib/links';
import { showDismissableAlertToast } from '@/components/Toasts';

import { useRunOnSubscription } from './useRunOnSubscription';

const subscription = graphql(`
  subscription ComplianceChanges_BlockOperationToast {
    onUpdateIdentityBlockStatus {
      organisationId
      identity {
        id
        details {
          displayName
          status
        }
      }
      statusChange {
        result
        fromStatus
        toStatus
      }
      updatedBy {
        id
      }
    }
  }
`);

export function ToastOnClientBlockOperationFinished({ currentUserId: myUserId }: { currentUserId: string }) {
  const t = useTranslations('Toasts.ComplianceToasts.BlockUnblockClient');

  useRunOnSubscription(subscription, {
    skip({ loading, data }) {
      if (loading || !data?.onUpdateIdentityBlockStatus) return true;
      const { identity, statusChange, updatedBy } = data.onUpdateIdentityBlockStatus;

      if (statusChange.result === StatusChangeResult.Pending) return true;

      // Only show if it's triggered by the current user
      if (updatedBy.id !== myUserId) return true;

      // Check that we are not in the same page as the form
      const manageClientRegexp = new RegExp(`^${LinkTo.complianceProfileUserAccess({ clientId: identity.id })}`);
      if (manageClientRegexp.test(window.location.pathname)) {
        return true;
      }

      return false;
    },
    onData({ data }) {
      if (!data?.onUpdateIdentityBlockStatus) return;
      const { identity, statusChange } = data.onUpdateIdentityBlockStatus;

      // We consider pending operations as skipped
      const operation = t(statusChange.toStatus === IdentityStatus.Blocked ? 'operatonBlock' : 'operationUnblock');
      const isSuccessful = statusChange.result === StatusChangeResult.Success;

      const message = t.rich(isSuccessful ? 'success' : 'error', {
        name: identity.details.displayName,
        operation,
        view: () => (
          <LinkInternal href={LinkTo.complianceProfileUserAccess({ clientId: identity.id })} variant="underline">
            {t('view')}
          </LinkInternal>
        ),
      });

      showDismissableAlertToast(isSuccessful ? 'Successful' : 'Error', message, isSuccessful ? 'success' : 'error');
    },
  });

  return null;
}
